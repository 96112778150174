<template>
  <div>
    <NavTitle :title="title"></NavTitle>
    <el-alert title="配置好的海报将按照当前顺序从上往下开始依次轮播" type="warning" show-icon :closable="false"></el-alert>
    <div class="pl20 pr20 mt24">
      <div class="poster-list">
        <div class="poster-item" v-for="(item, index) in posterList" :key="index">
          <LoadImage class="img" :src="item.pic" mode="fill"></LoadImage>
          <div class="operate-row">
            <div class="btn-delete" @click="handleDelete(index)">
              <img src="@/assets/images/icon-delete.png">
              <span class="red-text">删除</span>
            </div>
            <div class="flex items-center">
              <div class="btn" @click="toEdit(index)">
                <img src="@/assets/images/icon-edit.png">
                <span>编辑配置</span>
              </div>
              <div class="btn" @click="movePre(index)">
                <img class="icon-pre" src="@/assets/images/next.png">
                <span>前移</span>
              </div>
              <div class="btn" @click="moveNext(index)">
                <img src="@/assets/images/next.png">
                <span>后移</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-add" @click="toAdd">
          <img src="@/assets/images/add-fill.png">
          <div>添加配置</div>
        </div>
      </div>
    </div>
    <!-- <div>{{JSON.stringify(this.posterList)}}</div> -->
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import LoadImage from '@/components/LoadImage'
import { resItemsListApi, resItemsManageApi } from '@/api/resConf.js'
export default {
  name: 'PosterList',
  components: {
    NavTitle,
    LoadImage
  },
  data() {
    return {
      resType: this.$route.query.resType,
      confId: this.$route.query.confId,
      title: this.$route.query.title || '',
      posterList: []
    }
  },
  created() {
    this.getPosterList()
    
  },
  methods: {
    getPosterList() {
      const formData = {
        confId: this.confId,
        grayConfig: this.$store.state.isResourceGray,
        resType: this.resType
      }
      console.log('formData', formData)
      resItemsListApi(formData).then(res => {
        console.log('配置详情列表', res)
        this.posterList = res.data.banners || []
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    toAdd() {
      this.$router.push({
        path: '/resource/posterEdit',
        query: {
          resType: this.resType,
          confId: this.confId
        }
      })
    },
    toEdit(index) {
      this.$router.push({
        path: '/resource/posterEdit',
        query: {
          resType: this.resType,
          confId: this.confId,
          isEdit: true,
          editIndex: index
        }
      })
    },
    handleDelete(index) {
      this.$confirm('确认删除该海报吗？').then(action => {
        if(action == 'confirm') {
          this.posterList.splice(index, 1)
          this.saveConfig()
        }
      })
    },
    movePre(index) {
      if(index <= 0) return
      const preItem = this.posterList[index - 1]
      this.$set(this.posterList, index - 1, this.posterList[index])
      this.$set(this.posterList, index, preItem)
      this.saveConfig()
    },
    moveNext(index) {
      if(index == this.posterList.length - 1) return
      const nextItem = this.posterList[index + 1]
      this.$set(this.posterList, index + 1, this.posterList[index])
      this.$set(this.posterList, index, nextItem)
      this.saveConfig()
    },
    saveConfig() {
      const formData = {
        confId: this.confId,
        grayConfig: this.$store.state.isResourceGray,
        resType: this.resType,
        banners: this.posterList
      }
      console.log('formData', formData)
      resItemsManageApi(formData).then(res => {
        this.$tips({message: res.msg, type: 'success'})
        this.getPosterList()
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
    
  }
}
</script>

<style lang="scss" scoped>
.el-alert {
  padding: 12px 24px;
}
.poster-list {
  display: flex;
  flex-wrap: wrap;
}
.poster-item {
  width: 375px;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 20px 20px 0;
  background-color: #fff;
  .img {
    width: 375px;
    height: 135px;
  }
  .operate-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    padding: 0 16px;
    border-top: 1px solid #f5f5f5;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  .btn-delete {
    display: flex;
    align-items: center;
    color: var(--theme-color-red);
    cursor: pointer;
  }
  .btn {
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  }
  .icon-pre {
    transform: rotate(180deg);
  }
}

.btn-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 375px;
  height: 180px;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  color: var(--theme-color);
  background-color: #fff;
  cursor: pointer;
  img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
  }
}
</style>